import React, { FC, useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import { useInterval } from 'ahooks';
import { getTokenStatus } from '../service';
import { message } from 'antd';

interface WxCodeProps {
    codeUrl: string;
    token: string;
    refreshCodeUrl: () => void;
    current: number;
    currentChange: (current: number) => void;
    getUserInfoUrl: (userInfoUrl: string) => void;
}

const WxCode: FC<WxCodeProps> = (props) => {
    const intervalTime = 2000;
    const [interval, setInterval] = useState<number | undefined>(undefined);
    const [userInfoUrl, setUserInfoUrl] = useState<string>('');

    // 请求数据获取扫码状态
    const requestGetTokenStatus = () => {
        getTokenStatus({
            token: props.token,
        }).then(res => {
            // type: 0 未扫码, 1 成功, 2失败
            const { type, user_info } = res.data;

            if (type === 1) {
                setInterval(undefined);
                setUserInfoUrl(user_info);
                props.currentChange(1);
                props.getUserInfoUrl(user_info);
            }

            if (type === 2) {
                setInterval(undefined);
                message.warning("扫码失败, 请刷新重试!").then(() => {})
            }
        });
    };

    // 周期请求
    useInterval(() => {
        requestGetTokenStatus();
    }, interval);

    useEffect(() => {
        if (props.token && props.current === 0) {
            setInterval(intervalTime);
        } else {
            setInterval(undefined);
        }
    }, [props.token, props.current]);

    return (
        <div style={{ paddingTop: 20 }}>
            <h4 style={{ fontSize: 16, fontWeight: 600, textAlign: 'center' }}>微信扫码授权</h4>
            <p>打开微信扫码授权</p>
            <p><a onClick={() => {
                props.currentChange(1);
            }}>跳过微信扫码</a></p>
            <div style={{ textAlign: 'center' }}>
                <QRCode
                    value={props.codeUrl} // 生成二维码的内容
                    size={300} // 二维码的大小
                    fgColor="#000000" // 二维码的颜色
                    // imageSettings={{ // 中间有图片logo
                    //     src: logo,
                    //     height: 60,
                    //     width: 60,
                    //     excavate: true
                    // }}
                />
            </div>
            <p>二维码失效了？<a onClick={() => {
                props.refreshCodeUrl();
            }}>刷新二维码</a></p>
        </div>
    );
};

export default WxCode;
