import request from "../../utils/request";

// 获取二维码地址
export async function getCodeToken(params: any = {}) {
    return request('/api/v1/wmt/getCodeToken', {
        method: 'GET',
        params: params,
    });
}

// 获取扫码结果
export async function getTokenStatus(params: any = {}) {
    return request('/api/v1/wmt/getTokenStatus', {
        method: 'GET',
        params: params,
    });
}

// 获取授权状态
export async function getErpStatus(params: any = {}) {
    return request('/api/v1/wmt/getErpStatus', {
        method: 'GET',
        params: params,
    });
}

