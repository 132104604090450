import axios from 'axios';
import './axiosSetting';

const request = async (_url: string, _options: any = {}) => {
    const url = _url; // baseURL 在 axiosSetting 内设置, 这样如果 _url 是一个绝对 url 则不会被 axios 添加 baseURL
    _options.method || (_options.method = 'POST');
    const options = {
        url,
        ..._options
    }

    return axios(options);
}

export default request;
