import React, { FC, useEffect, useState } from 'react';
import dy from '../../../assets/icon/dy.png';
import jd from '../../../assets/icon/jd.png';
import ks from '../../../assets/icon/ks.png';
import pdd from '../../../assets/icon/pdd.png';
import tb from '../../../assets/icon/tb.png';
import tm from '../../../assets/icon/tm.png';
import xhs from '../../../assets/icon/xhs.png';
import albb from '../../../assets/icon/albb.png';
import { Modal, Button, message, Avatar, Tag } from 'antd';
import { CheckCircleFilled, LoadingOutlined, CloseCircleFilled, UserOutlined } from '@ant-design/icons';
import { UrlState } from '../index';
import dayjs from 'dayjs';
import { getErpStatus } from '../service';
import { useInterval } from 'ahooks';

interface ShopItem {
    title: string;
    icon: string;
    type: number;
}

interface ShopSelectProps {
    userInfoUrl: string;
    urlState: UrlState;
}

const ShopSelect: FC<ShopSelectProps> = (props) => {
    const shopList: {
        [propName: string]: ShopItem
    } = {
        1: {
            title: '淘宝',
            icon: tb,
            type: 1,
        },
        2: {
            title: '拼多多',
            icon: pdd,
            type: 2,
        },
        3: {
            title: '抖音',
            icon: dy,
            type: 3,
        },
        4: {
            title: '快手',
            icon: ks,
            type: 4,
        },
        5: {
            title: '京东',
            icon: jd,
            type: 5,
        },
        6: {
            title: '阿里巴巴',
            icon: albb,
            type: 6,
        },
        7: {
            title: '小红书',
            icon: xhs,
            type: 7,
        },
    };
    const intervalTime = 2000;
    const wmtErpUrl = "http://scrm.vv-tool.com/login-with?token_own=";

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [time, setTime] = useState<string>(''); // 授权时间, 第一次弹框就会定下来, 除非刷新页面不然后面不变了
    const [interval, setInterval] = useState<number | undefined>(undefined);
    const [authInfo, setAuthInfo] = useState<any>({
        type: 0,
    }); // 授权的授权信息, 每次授权都会更新这个信息

    const handleOk = () => {
        window.location.href = `${wmtErpUrl}${props.userInfoUrl}`;
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // 点击店铺
    const shopClick = (item: ShopItem) => {
        // if (item.type === props.urlState.type) {
            if (!time) {
                setTime(dayjs().format('YYYY-MM-DD HH:mm:ss'));
            }
            window.open(props.urlState.url);
            setIsModalVisible(true);
        // }
    };

    // 请求数据获取授权状态
    const requestGetErpStatus = () => {
        getErpStatus({
            type: props.urlState.type,
            uid: props.urlState.uid,
            time,
        }).then(res => {
            // type: 0 未授权, 1 成功, 2失败
            const { type, user_info } = res.data;

            setAuthInfo(res.data);

            if (type === 1) {
                setInterval(undefined);
            }

            if (type === 2) {
                setInterval(undefined);
                message.warning("授权失败, 请刷新重试!").then(() => {
                });
            }
        });
    };

    // 周期请求
    useInterval(() => {
        requestGetErpStatus();
    }, interval);

    // 根据弹框状态判断是否开始请求
    useEffect(() => {
        if (isModalVisible) {
            setInterval(intervalTime);
        } else {
            setInterval(undefined);
        }
    }, [isModalVisible]);

    return (
        <div style={{ paddingTop: 20 }}>
            <h4 style={{ fontSize: 16, fontWeight: 600, textAlign: 'center' }}>选择电商平台进行授权，登录后可获得以下平台使用权限，点击前往</h4>
            {/* 平台列表 */}
            <div className="flex flex-wrap" style={{ padding: 20 }}>
                {
                    Object.keys(shopList).map(item => {
                        return <div key={shopList[item].title} style={{ width: 72, height: 72, margin: '0 10px 10px 0', cursor: 'pointer', opacity: shopList[item].type === props.urlState.type ? 1 : 0.4 }} onClick={() => {
                            shopClick(shopList[item]);
                        }}>
                            <img style={{ width: '100%', height: '100%' }} src={shopList[item].icon} alt={shopList[item].title} />
                        </div>;
                    })
                }
            </div>
            <Modal
                title="授权状态"
                cancelText="取消"
                okText="开始发货"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="submit" type="primary" disabled={authInfo.type !== 1} onClick={handleOk}>开始发货</Button>,
                ]}
            >
                <div>
                    {/* 授权状态 */}
                    <div className="flex items-center justify-center">
                        {
                            // 授权中
                            authInfo.type === 0 && (
                                <>
                                    <LoadingOutlined style={{ fontSize: 30 }} />
                                    <span style={{ fontSize: 16, color: '#333', marginLeft: 6 }}>授权中</span>
                                </>
                            )
                        }
                        {
                            // 授权成功
                            authInfo.type === 1 &&
                            <>
                                <CheckCircleFilled style={{ fontSize: 30, color: '#6B9CFF' }} />
                                <span style={{ fontSize: 16, color: '#333', marginLeft: 6 }}>授权成功</span>
                            </>
                        }
                        {
                            // 授权失败
                            authInfo.type === 2 &&
                            <>
                                <CloseCircleFilled style={{ fontSize: 30, color: '#fd0246' }} />
                                <span style={{ fontSize: 16, color: '#333', marginLeft: 6 }}>授权失败</span>
                            </>
                        }
                    </div>
                    {/* 授权成功后的信息 */}
                    {
                        authInfo.type === 1 && (
                            <div className="flex items-cener" style={{ padding: 20 }}>
                                <Avatar size={46} style={{ backgroundColor: '#87d068', marginRight: 10 }} icon={<UserOutlined />} />
                                <div className="flex-1">
                                    <p>{authInfo.shop_name}</p>
                                    <Tag color="#f50">{shopList[props.urlState.type].title}</Tag>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Modal>
        </div>
    );
};

export default ShopSelect;
