import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Steps, Card, message, Spin } from 'antd';
import './index.css';
import WxCode from './_components/WxCode';
import ShopSelect from './_components/ShopSelect';
import { getCodeToken } from './service';

export interface UrlState {
    uid: number | null;
    type: number | string;
    url: string;
}

export default function Home() {

    let [searchParams, setSearchParams] = useSearchParams(); // url 查询参数
    const [current, setCurrent] = useState<number>(1); // 授权页进入后默认选择第二步
    const [urlState, setUrlState] = useState<UrlState>({
        uid: null,
        type: '',
        url: '',
    }); // url 里面的 state 对应的字符串对象解析后的数据
    const [codeUrl, setCodeUrl] = useState<string>('');
    const [token, setToken] = useState<string>(''); // 周期查询扫码状态的 key
    const [loading, setLoading] = useState<boolean>(false);
    const [userInfoUrl, setUserInfoUrl] = useState<string>('');

    const currentChange = (current: number) => {
        setCurrent(current);
    };

    // 处理 url state 数据
    const handleState = () => {
        if (!searchParams.has('state')) { // url 内没有 state 数据
            message.warning('url参数有误, 请联系客服!').then(() => {
            });

            return false;
        }
        let stateString: string | null = searchParams.get('state');
        let stateInfo = stateString && JSON.parse(stateString);

        stateInfo.uid = +window.atob(stateInfo.uid);
        setUrlState(stateInfo);

        return stateInfo;
    };

    // 请求数据
    const requestGetCodeToken = () => {
        setLoading(true);
        getCodeToken({
            uid: urlState.uid,
        }).then(res => {
            const { url, token } = res.data;

            setCodeUrl(url);
            setToken(token);
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        handleState();
    }, []);

    useEffect(() => {
        if (urlState.uid) {
            requestGetCodeToken();
        }
    }, [urlState.uid]);

    return (
        <div className="home-steps">
            <Card>
                <h3 style={{ textAlign: 'center' }}>微信扫码授权获取完成店铺授权使用发货打单软件</h3>
                <Steps
                    type="navigation"
                    size="small"
                    current={current}
                    onChange={currentChange}
                >
                    <Steps.Step title="第一步：微信扫码授权" />
                    <Steps.Step title="第二步：选择店铺授权" />
                </Steps>
                <Spin spinning={loading} tip="Loading...">
                    {
                        // 微信授权扫码
                        current === 0 ? <WxCode
                            token={token}
                            codeUrl={codeUrl}
                            current={current}
                            currentChange={(current: number) => {
                                currentChange(current);
                            }}
                            refreshCodeUrl={requestGetCodeToken}
                            getUserInfoUrl={(userInfoUrl) => {
                                setUserInfoUrl(userInfoUrl);
                            }}
                        /> : null
                    }
                </Spin>
                {
                    // 选择店铺授权
                    current === 1 ? <ShopSelect urlState={urlState} userInfoUrl={userInfoUrl} /> : null
                }
            </Card>
        </div>
    );
}
