import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import LayoutBasic from '../layout/basic'
import Home from '../pages/home';

export default function RouterConfig() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LayoutBasic />}>
                    {/* 重定向到 home 路由 */}
                    <Route path="" element={<Navigate to="/home" replace={true} />} />
                    <Route path="home" element={<Home />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
